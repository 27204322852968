// @flow
import React from 'react';
import Footer from 'site/Footer';
import Header from 'site/Header';
import CitiesBar from 'site/Home/CitiesBar';
import CordCutting from 'site/Home/CordCutting';
import CustomerReview from 'site/Home/CustomerReview';
import Hero from 'site/Home/Hero';
import HowItWorks from 'site/Home/HowItWorks';
import OurService from 'site/Home/OurService';
import OurVision from 'site/Home/OurVision';
import ReadMore from 'site/Home/ReadMore';
import HomeShowcase from 'site/Home/Showcase';
import Page from 'site/Page';

function Home() {
  return (
    <Page>
      <Header />
      <Hero />
      <OurService />
      <HowItWorks />
      <CustomerReview />
      <HomeShowcase />
      <OurVision />
      <ReadMore />
      <Footer />
    </Page>
  );
}

export default Home;
