// @flow
import React, {PureComponent} from 'react';

import {withStyles, type Classes, type Theme} from 'ui';
import {colors} from 'theme/v2';
import {FullWidthContent} from 'layout';
import {Section, SectionHeadline, SectionOverline} from 'site/Section';
import Fortune from './Fortune.svg';

const styles = (theme: Theme) => ({
  root: {
    backgroundColor: colors.elm2,
  },
  content: {
    textAlign: 'center',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
  overline: {
    color: colors.white,
    fontWeight: 700,
  },
  headline: {
    color: colors.white,
    fontSize: 40,
    lineHeight: 1.3,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 740,
    [theme.breakpoints.down('xs')]: {
      fontSize: 32,
      lineHeight: 1.35,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  logo: {
    flex: '0 0 auto',
    height: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(1.5),
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
    },
  },
});

type Props = {
  // injected
  classes: Classes<typeof styles>,
};

class CordCutting extends PureComponent<Props> {
  render() {
    const {classes} = this.props;
    return (
      <Section className={classes.root}>
        <FullWidthContent contentClassName={classes.content}>
          <SectionOverline className={classes.overline}>
            MONTHLY SAVINGS
          </SectionOverline>
          <SectionHeadline className={classes.headline}>
            "Cord cutters averaged $85 a month in savings, even including the
            amounts spent for Internet service and streaming video providers."
          </SectionHeadline>
          <a href="https://fortune.com/2018/07/23/cord-cutters-netflix-average-bill/">
            <Fortune className={classes.logo} />
          </a>
        </FullWidthContent>
      </Section>
    );
  }
}

export default withStyles(styles)(CordCutting);
