// @flow
import React, {PureComponent} from 'react';

import Home from 'site/Home';

type Props = {};

class HomePage extends PureComponent<Props> {
  render() {
    return <Home />;
  }
}

export default HomePage;
