// @flow
import React, {PureComponent} from 'react';
import Link from 'next/link';
import classnames from 'classnames';

import {Typography, withStyles, type Classes, type Theme} from 'ui';
import {PurpleBar} from 'site/Section';

// estimated height based on text-wrapping
export function citiesBarHeight(theme: Theme, breakpoint: string): number {
  switch (breakpoint) {
    case 'xs':
    case 'sm':
      return theme.spacing(15);
    case 'md':
    case 'lg':
    case 'xl':
    default:
      return theme.spacing(11);
  }
}

const styles = (theme: Theme) => ({
  link: {
    fontSize: '18px',
    color: [theme.palette.common.white, '!important'],
    textDecoration: 'none',
    fontWeight: 400,
    paddingTop: 7,
    paddingBottom: 7,
    [theme.breakpoints.only('xs')]: {
      marginLeft: theme.spacing(0.75),
      marginRight: theme.spacing(0.75),
    },
  },
  divider: {
    backgroundColor: theme.palette.common.white,
    width: 1,
    height: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  hiddenXs: {
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
});

type Props = {
  // injected
  classes: Classes<typeof styles>,
};

class CitiesBar extends PureComponent<Props> {
  render() {
    const {classes} = this.props;

    return (
      <PurpleBar>
        <Link href="/cities/alameda" passHref>
          <Typography className={classes.link} component="a" align="center">
            Alameda
          </Typography>
        </Link>
        <div className={classnames(classes.divider, classes.hiddenXs)} />
        <Link href="/cities/sanleandro" passHref>
          <Typography className={classes.link} component="a" align="center">
            San Leandro
          </Typography>
        </Link>
        <div className={classnames(classes.divider, classes.hiddenXs)} />
        <Link href="/cities/oakland" passHref>
          <Typography className={classes.link} component="a" align="center">
            Oakland
          </Typography>
        </Link>
      </PurpleBar>
    );
  }
}

export default withStyles(styles)(CitiesBar);
